<div class="alert tenant-warning no-print h-auto" *ngIf="showWarning">
    <div class="d-flex align-items-center" style="flex: 1">
        <span class="material-icons-outlined" style="color: #1D9AD6; font-size: 1.8rem;">
            notifications_active
        </span>
        <div class="font-11 ml-2">
            Tài khoản của bạn chưa khởi tạo tổng đài. Vui lòng khởi tạo để bắt đầu sử dụng.
        </div>
    </div>
    <button class="btn btn-primary" (click)="createTenant()">
        <i class="fa fa-spin fa-circle-o-notch mr-1" *ngIf="loading"></i>
      Tạo tổng đài
    </button>
  </div>
  